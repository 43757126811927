
































import Vue from "vue";
import users from "../data/users.json";
import {getPlayers} from "@/api/api";
import {Player} from "@/types/players";

export default Vue.extend({
  name: "Leaderboard",
  data() {
    return {
      users: Array<any>(),
      headers: [
        { text: "Rank", value: "rank" },
        { text: "Name", value: "name" },
        { text: "Score", value: "score" }]
    };
  },
  methods: {
    colourRows(item: any) {
      let s = [];
      if (item.username == this.$store.state.user.username) s.push("you");
      if (item.rank == "🥇") s.push("gold--text");
      else if (item.rank == "🥈") s.push("silver--text");
      else if (item.rank == "🥉") s.push("bronze--text");
      return s.join(" ");
    }
  },
  async mounted() {
    this.users = (await getPlayers()).filter(it => it.id.includes("@")).sort((a, b) => {
        return a.score === b.score ? a.fullname.localeCompare(b.fullname) : (b.score ?? 0) - (a.score ?? 0);
      }).map((it, index) => ({
        rank: index == 0 ? "🥇" : index == 1 ? "🥈" : index == 2 ? "🥉" : index+1,
        name: it.username,
        username: it.username,
        score: it.score,
        pfp: `data:image/png;base64,${it.pfp}`
        // pfp: it.profilePicture
      }));
    console.log(this.users);
  }
});
