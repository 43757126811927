



















import Vue from "vue";

export default Vue.component("challenge-card", {
  props: ["challenge"]
});
