


































































































import Vue from "vue";

import school_list from "../data/schools.json";

import challenges from "../data/games.json";

import { timeAgo, convertSQLTimestampToDate } from "@/util/datetime";
import { getPlayers } from "@/api/api";
import { Player } from "@/types/players";
import { updateDetails, deleteAccount, getChallenges } from "@/api/api";
import ImageInput from "@/components/ImageInput.vue";

export default Vue.extend({
  name: "Profile",
  components: {
    ImageInput: ImageInput
  },

  data: function () {
    return {
      users: Array<Player>(),
      user: {
        id: "", fullname: "", score: 0,
        num_games: 0, about: "", school: "",
        pfp: "", username: ""
      },
      avatar: {image : ""},
      saved: true,
      editAbout: false,
      tempAbout: "",
      schools: school_list,
      timeAgo: timeAgo,
      headers: [
        {text: "Challenge", value: "title"},
        {text: "Winner", value: "winner"},
        {text: "Last Solved", value: "lastUpdated"}],
      challenges:  Array<any>()
    };
  },
  watch:{
    avatar: {
      handler: function() {
        // url in this.avatar.image
        if(this.avatar !== null) this.user.pfp = this.avatar.image;
        console.log(this.user);
        updateDetails(this.user);
        this.$store.state.user.pfp = this.avatar.image;
      },
      deep: true
    }
  },
  methods: {
    wordCount(text: string): number {
      let content = text;
      content = content.replace(/<\S[^><]*>/gi, "");
      return content.match(/\w+/g) ? (content.match(/\w+/g) || "").length : 0;
    },
    getCookie(cname: string) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    updateDetails() {
      updateDetails(this.user);
      this.$store.state.user = this.user;
    },
    deleteUser() {
      deleteAccount(this.user.id);
      localStorage.removeItem("jwt");
      localStorage.removeItem("refresh");
      localStorage.removeItem("renew");
      this.$store.state.user = null;
    }
  },
  async mounted() {
    try {
    this.user = (await getPlayers()).filter(it => it.username == this.$route.params.username)[0];
    } catch (error) {
      this.user = this.$route.params.username == this.$store.state.user.username ? this.$store.state.user: {
        id: "", fullname: "", score: 0,
        num_games: 0, about: "", school: "",
        pfp: "", username: ""
      };
    }
    this.avatar.image = this.user.pfp;
    let results = await getChallenges(this.user.id);
    console.log(results);
    this.challenges = (await getChallenges(this.user.id)).map(it => {
      let challenge = challenges.filter(it2 => it2.id == it.level)[0];
      return {
        level: it.level,
        title: challenge.title,
        winner: it.winner ? "You" : "AI",
        lastUpdated: convertSQLTimestampToDate(it.lastupdated)
      };
    });
    console.log(this.challenges);
  }
});

