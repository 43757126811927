

















import Vue from "vue";

import ChallengeCard from "@/components/ChallengeCard.vue";
import games from "../data/games.json";

export default Vue.extend({
  name: "Challenges",
  components: {
    ChallengeCard
  },
  data() {
    return {
      challenges: games
    };
  }
});
