






















































































































































































































import Vue from "vue";
import {getBase64, createUser, checkLoggedIn, updateDetails, resolveLogin, checkUsername} from "@/api/api";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GoogleSignInButton from "vue-google-identity-login-btn";
import { Player } from "./types/players";
import school_list from "../data/schools.json";

export default Vue.extend({
  name: "App",
  el: "#app",
  components: {},
  directives: {
    GoogleSignInButton
  },
  data: () => ({
    drawerShown: false,
    loginItems: ["Login", "Register"],
    rules: (input: string | undefined) => {
          if (input === undefined || input === "") return true;
          if (input.trim().toLowerCase().match(/^[a-z0-9_-]+$/) !== null) {
            return true;
          }
          return "Invalid";
        },
    username: "",
    school: "",
    fullname: "",
    password: "",
    backgroundImg:
      "https://images.unsplash.com/photo-1597407068889-782ba11fb621?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8ZGFyayUyMG1vdW50YWlufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
    font: window.innerWidth < 1000 ? 3 * 0.75 : 3,
    showPassword: false,
    hideSubtitle: false,
    imgIsLoaded: false,
    showLoginError: false,
    tab: null,
    clientId: "596255395612-2s6ld1u25tfcuefaiogu7jiorsj46e6i.apps.googleusercontent.com"
  }),
  methods: {
    async onGoogleAuthSuccess (jwtCredentials: string) {
      console.log(jwtCredentials);
      const profileData = JSON.parse( atob(jwtCredentials.split(".")[1]) );
      console.table(profileData);
      let pfp = (getBase64(profileData.picture));
      createUser(profileData.email, profileData.name, profileData.given_name, await pfp);
      this.$store.state.user = {
        id: profileData.email,
        fullname: profileData.name,
        username: profileData.given_name,
        school: "",
        about: "",
        pfp,
        score: 0,
        num_games: 0
      };
    },
    checkAvailability(input: string): boolean {
      return !checkUsername(input);
    },
    scrollToMain() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs["onboarding"].scrollIntoView({ behavior: "smooth" });
    },
    onScroll() {
      if (window.scrollY > this.height * 0.7) {
        this.font = 1;
        this.hideSubtitle = true;
      } else {
        this.font = 3;
        this.hideSubtitle = false;
      }
      if (this.width < 1000) {
        this.font *= 0.75;
      }
    },
    imgLoaded() {
      this.imgIsLoaded = true;
    },
    create() {
      sessionStorage.setItem("username", this.username);
      sessionStorage.setItem("school", this.school);
      sessionStorage.setItem("fullname", this.fullname);
      sessionStorage.setItem("creating", "1");
      this.login("true");
    },
    login(create: string) {
      sessionStorage.setItem("logging", "1");
      const currentLocation = window.location.origin;
      console.log(currentLocation);
      window.location.href = `http://starvation-api.buildingblocs.sg/login?next=${currentLocation}&create=${create}`;
    },
      getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
      logout() {
        localStorage.removeItem("jwt");
        localStorage.removeItem("refresh");
        localStorage.removeItem("renew");
        this.$store.state.user = null;
      }
  },
  computed: {
    routes(): Array<{
      name: string;
      route: string;
      icon: string;
    }> {
      // Add routes here to correspond to router.ts
      return [
        {
          name: "Home",
          route: "/",
          icon: "mdi-home-variant"
        },
        {
          name: "Profile",
          route: `/users/${(this.$store.state.user || {username: ""}).username}`,
          icon: "mdi-account"
        },
        {
          name: "Leaderboard",
          route: "/leaderboard",
          icon: "mdi-trophy-variant"
        }
      ];
    },
    height() {
      return window.innerHeight;
    },
    width() {
      return window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);

    // check logged in
    checkLoggedIn().then((resp) => {
      console.log(resp.data);
      if (resp.data.status) {
        if (sessionStorage.getItem("creating")) {
          const username = sessionStorage.getItem("username") ?? "";
          const fullname = sessionStorage.getItem("fullname") ?? "";
          const school = sessionStorage.getItem("school") ?? "";
          let player = resp.data.user as Player;
          player.username = username;
          player.fullname = fullname;
          player.school = school;
          updateDetails(player).then(() => {
            this.$store.state.user = player;
          });
        } else {
          this.$store.state.user = resp.data.user;
        }
        sessionStorage.clear();
      } else {
        if (sessionStorage.getItem("logging")) {
          const uri = window.location.search;
          const params = new URLSearchParams(uri);
          const code = params.get("code");
          console.log(code);
          if (code) {
              resolveLogin(code as string).then((resp) => {
                localStorage.setItem("jwt", resp.data.access_token);
                localStorage.setItem("refresh", resp.data.refresh_token);
                localStorage.setItem("renew", resp.data.renew);
                window.location.href = window.location.origin;
              });
          } else {
            sessionStorage.clear();
            this.showLoginError = true;
          }
        } else {
          sessionStorage.clear();
        }
      }
    });

    // if (this.getCookie("username") != "" && this.getCookie("password")) {
    //   this.username = this.getCookie("username");
    //   this.password = this.getCookie("password");
    //   this.login();
    // }
  }
});
